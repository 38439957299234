.ErrorPage {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color:var(--bs-dark);
    font-size: var(--font-family-main);

    .btn {
        margin-top: 10px;
        color: var(--bs-white);
    }
}
